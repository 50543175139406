import { useOutletContext } from '@remix-run/react'

import { Page } from '~/components/page'
import { Website_Info } from '~/website.models'

export default function Index() {
  const context = useOutletContext<Website_Info>()

  const { config, pages } = context

  const currentPage = pages?.find(p => p.type === 'home')

  if (!currentPage) {
    throw new Response(null, {
      status: 500,
      statusText: 'No homepage data for this location',
    })
  }

  return <Page config={config} page={currentPage} />
}
